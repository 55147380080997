import Cookies from 'js-cookie'
import { domReady } from '@/utils/dom-ready'

import { COOKIE_NAME_AGREED_COOKIES } from '../constants'

domReady(() => {
  const cookiesBanner = document.querySelector('.cookies-consent')

  if (cookiesBanner) {
    const sixMonthsInDays = 6 * 30  // Obviously months have different numbers of days, but this should be good enough

    cookiesBanner.querySelector('#agree-cookies').addEventListener('click', () => {
      Cookies.set(COOKIE_NAME_AGREED_COOKIES, 'all', {expires: sixMonthsInDays})
      document.dispatchEvent(new Event(COOKIE_NAME_AGREED_COOKIES))
      cookiesBanner.style.display = 'none'
    })

    cookiesBanner.querySelector('#accept-essential').addEventListener('click', () => {
      Cookies.set(COOKIE_NAME_AGREED_COOKIES, 'essential', {expires: sixMonthsInDays})
      cookiesBanner.style.display = 'none'
    })

    if (!Cookies.get(COOKIE_NAME_AGREED_COOKIES)) {
      cookiesBanner.style.display = 'block'
    }
  }
})

